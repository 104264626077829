
import { defineComponent } from 'vue';
import SettingsImages from '@/components/users/SettingsImages.vue';
import SettingsSubscription from '@/components/users/SettingsSubscription.vue';
import SettingsThirdPartyAccounts from '@/components/users/SettingsThirdPartyAccounts.vue';

export default defineComponent({
  components: {
    SettingsImages,
    SettingsSubscription,
    SettingsThirdPartyAccounts,
  },
  data: () => ({
    step: 'images' as 'images' | 'third_party_accounts' | 'subscription',
  }),
});

<template>
  <h3>
    <template v-if="step === 'images'">
      Set Your Profile Image and Cover Image
    </template>

    <template v-else-if="step === 'third_party_accounts'">
      Enter Your Gaming & Social Media Accounts
    </template>

    <template v-else-if="step === 'subscription'">
      Subscription Fee
    </template>
  </h3>

  <settings-images
    v-if="step === 'images'"
    mode="onboard"
    @done="step = 'third_party_accounts'"
  />

  <settings-third-party-accounts
    v-else-if="step === 'third_party_accounts'"
    mode="onboard"
    @back="step = 'images'"
    @done="step = 'subscription'"
  />

  <settings-subscription
    v-else-if="step === 'subscription'"
    mode="onboard"
    @back="step = 'third_party_accounts'"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SettingsImages from '@/components/users/SettingsImages.vue';
import SettingsSubscription from '@/components/users/SettingsSubscription.vue';
import SettingsThirdPartyAccounts from '@/components/users/SettingsThirdPartyAccounts.vue';

export default defineComponent({
  components: {
    SettingsImages,
    SettingsSubscription,
    SettingsThirdPartyAccounts,
  },
  data: () => ({
    step: 'images' as 'images' | 'third_party_accounts' | 'subscription',
  }),
});
</script>


import { defineComponent } from 'vue';
import OnboardCustomer from '@/components/users/OnboardCustomer.vue';
import OnboardIpg from '@/components/users/OnboardIpg.vue';

export default defineComponent({
  components: {
    OnboardCustomer,
    OnboardIpg,
  },
  async beforeRouteEnter(to, from, next) {
    await new Promise<void>((resolve) => {
      const appNotYetLaunchedContent = document.querySelector('.app-not-yet-launched-content');

      if (appNotYetLaunchedContent) {
        const observer = new MutationObserver(() => {
          observer.disconnect();
          resolve();
        });

        observer.observe(appNotYetLaunchedContent, { attributes: true });
      } else {
        resolve();
      }
    });

    if (document.querySelector('.is-anonymous-or-not-onboarded')) {
      next();
    } else {
      next({ name: 'feed' });
    }
  },
  data: () => ({
    signupData: {
      signup_type: 'customer' as 'customer' | 'ipg' | 'both',
    },
  }),
  created() {
    const signupData = this.userData.signup_data;

    if (signupData) {
      this.signupData = signupData;
    } else {
      this.$router.push({ name: 'user_profile', params: { username: this.userData.username } });
    }
  },
  methods: {
    changeAccountTypePrompt() {
      let choices = [] as Array<{
        machine: 'customer',
        human: 'Customer',
      } | {
        machine: 'ipg',
        human: 'IPG',
      } | {
        machine: 'both',
        human: 'Self-subscribing IPG',
      }>;

      if (this.signupData.signup_type === 'customer') {
        choices = [
          {
            machine: 'ipg',
            human: 'IPG',
          },
          {
            machine: 'both',
            human: 'Self-subscribing IPG',
          },
        ];
      } else if (this.signupData.signup_type === 'ipg') {
        choices = [
          {
            machine: 'customer',
            human: 'Customer',
          },
          {
            machine: 'both',
            human: 'Self-subscribing IPG',
          },
        ];
      } else {
        choices = [
          {
            machine: 'customer',
            human: 'Customer',
          },
          {
            machine: 'ipg',
            human: 'IPG',
          },
        ];
      }

      this.$swal.fire({
        title: 'Switch Account Type?',
        text: 'Which account type would you like to sign up for instead?',
        customClass: {
          confirmButton: 'btn btn-primary',
          denyButton: 'btn btn-primary',
          cancelButton: 'btn btn-light',
        },
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: choices[0].human,
        denyButtonText: choices[1].human,
        preConfirm: () => {
          this.$swal.update({
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          setTimeout(async () => {
            this.changeAccountType(choices[0]);
          }, 0);

          return false;
        },
        preDeny: () => {
          this.$swal.update({
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          setTimeout(async () => {
            this.changeAccountType(choices[1]);
          }, 0);

          return false;
        },
      });
    },
    async changeAccountType(newAccountType: { machine: string; human: string; }) {
      const actionsDiv = document.querySelector('.swal2-actions') as HTMLDivElement;
      actionsDiv.innerText = `Switching to ${newAccountType.human}...`;

      const responseData = await this.api({
        url: 'users/signup_data/',
        method: 'POST',
        json: {
          new_signup_type: newAccountType.machine,
        },
      });

      if (responseData.status === 200) {
        this.signupData = responseData.body.signup_data;
        this.$swal.close();
      } else if (responseData.status === 400) {
        this.$router.push({ name: 'feed' });
        this.$swal.close();
      } else {
        this.$swal(
          'Failed to Switch Account Type',
          'Please check your connection and try again.',
        );
      }
    },
  },
});

<template>
  <settings-ipg mode="onboard" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SettingsIpg from '@/components/users/SettingsIpg.vue';

export default defineComponent({
  components: {
    SettingsIpg,
  },
});
</script>
